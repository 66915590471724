@font-face {
  font-family: 'iconfont';
  src: url('../utils/fonts/iconfont.eot');
  src: url('../utils/fonts/iconfont.eot?#iefix') format('embedded-opentype'),
    url('../utils/fonts/iconfont.woff2') format('woff2'),
    url('../utils/fonts/iconfont.woff') format('woff'),
    url('../utils/fonts/iconfont.ttf') format('truetype'),
    url('../utils/fonts/iconfont.svg?#iconfont') format('svg');
}
*{
  box-sizing: border-box;
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0;
  -moz-osx-font-smoothing: grayscale;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
